import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Button } from "@chakra-ui/react";
import { useState } from "react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";

export type TabsProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: GenericHeaderProps;
};

type TabProps = { uid: string; fields: { Headline: { value: string } } };

const TabsModule = (props: TabsProps) => {
    const phKey = `Tabs-${props.params?.DynamicPlaceholderId}`;
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();
    const [selectedTabId, setSelectedTabId] = useState<string>("");

    const tabsList = props.rendering.placeholders
        ? (Object.values(props.rendering.placeholders)[0] as unknown as TabProps[])
        : [];

    const tabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSelectedTabId(e.currentTarget.id);
        const tabPanels = document.querySelectorAll(".tab-panel");
        tabPanels.forEach((panel: HTMLDivElement) => {
            panel.style.display = "none";
            panel.setAttribute("aria-hidden", "true");
        });

        const tabId = e.currentTarget.getAttribute("aria-controls");
        const tabpanel = document.getElementById(tabId as string);
        if (tabpanel) {
            tabpanel.style.display = "block";
            tabpanel.setAttribute("aria-hidden", "true");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        const tabs = Array.from(document.querySelectorAll('[role="tab"]'));
        const currentIndex = tabs.findIndex((tab) => tab.id === e.currentTarget.id);

        if (e.key === "ArrowRight") {
            const nextIndex = (currentIndex + 1) % tabs.length;
            (tabs[nextIndex] as HTMLButtonElement).focus();
            (tabs[nextIndex] as HTMLButtonElement).click();
        } else if (e.key === "ArrowLeft") {
            const prevIndex = (currentIndex - 1 + tabs.length) % tabs.length;
            (tabs[prevIndex] as HTMLButtonElement).focus();
            (tabs[prevIndex] as HTMLButtonElement).click();
        }
    };

    return (
        <>
            <GenericHeader
                Id={props.rendering?.uid ?? props.rendering?.componentName}
                AnchorId={props.fields.AnchorId}
                HeaderHeadline={props.fields?.HeaderHeadline}
                HeaderSubHeadline={props.fields?.HeaderSubHeadline}
                HeaderCTA={props.fields?.HeaderCTA}
                headlineSize={props.params?.HeaderHeadlineSize}
                spacingSize={props.params?.Padding}
                sx={{ pb: "0!important" }}
            >
                {!pageEditing && (
                    <Box overflowX={"auto"}>
                        <Box
                            role="tablist"
                            display={"flex"}
                            gap={"sp16"}
                            px={{ base: "sp16", md: "sp32" }}
                            borderBottom={"1px solid black"}
                        >
                            {tabsList?.map((tab: TabProps, index: number) => {
                                const tabId = `${tab?.uid}--tab`;
                                const selected =
                                    (selectedTabId === "" && index === 0) ||
                                    selectedTabId === tabId;
                                return (
                                    <Button
                                        key={tab?.uid}
                                        role="tab"
                                        id={tabId}
                                        aria-controls={`${tab?.uid}--tabpanel`}
                                        aria-selected={selected}
                                        onClick={tabClick}
                                        variant={"tab"}
                                        onKeyDown={handleKeyDown}
                                    >
                                        {tab?.fields?.Headline?.value}
                                    </Button>
                                );
                            })}
                        </Box>
                    </Box>
                )}
            </GenericHeader>

            <Box
                {...(!pageEditing && {
                    sx: {
                        "& > div": {
                            display: "none",
                        },
                        "& > div:first-child": {
                            display: "block",
                        },
                    },
                })}
            >
                <Placeholder name={phKey} rendering={props.rendering} />
            </Box>
        </>
    );
};
export default TabsModule;
