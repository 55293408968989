import { Grid, Box, AspectRatio, VisuallyHidden, Link } from "@chakra-ui/react";
import {
    useSitecoreContext,
    type ComponentParams,
    type ComponentRendering,
    type Field,
    type ImageField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import SCHeading from "commons/sc/SCHeading";
import SCImage from "commons/sc/SCImage";
import SCText from "commons/sc/SCText";
import BundleIcon from "commons/ui/BundleIcon";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";
import { useI18n } from "next-localization";

export interface SpeakerProps {
    id: string;
    fields: {
        Company: Field<string>;
        Image: ImageField;
        LinkedInUrl: Field<string>;
        Name: Field<string>;
        Title: Field<string>;
    };
}

interface Fields extends GenericHeaderProps {
    Speakers: SpeakerProps[];
}

export type SpeakersListProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

export const SpeakerBox = (speaker: SpeakerProps) => {
    const sizes = useImageSizes({ base: 1, md: 1 / 3 });
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();
    const { t } = useI18n();

    return (
        <Box display={"flex"} flexDir={"column"}>
            {(pageEditing || speaker.fields?.Image?.value?.src) && (
                <AspectRatio
                    ratio={1}
                    borderRadius={"base"}
                    objectFit={"cover"}
                    overflow={"hidden"}
                    position="relative"
                    mb={"sp16"}
                >
                    <SCImage
                        imageField={speaker.fields?.Image}
                        sizes={sizes}
                        {...imageSize(Number(speaker.fields?.Image?.value?.width), 1, 1)}
                    />
                </AspectRatio>
            )}

            <SCHeading
                textField={speaker.fields?.Name}
                as="h2"
                lineHeight={"shorter"}
                size={"h5"}
                mb={"sp16"}
            />
            <SCText textField={speaker.fields?.Title} />
            <SCText textField={speaker.fields?.Company} />

            {(pageEditing || (!pageEditing && speaker.fields?.LinkedInUrl?.value !== "")) && (
                <Box display={"flex"} gap="sp12" alignItems={"center"}>
                    <BundleIcon
                        name="Linkedin"
                        width="24px"
                        height="24px"
                        border={"1px solid"}
                        borderRadius={"100%"}
                        p={"sp4"}
                    />
                    {pageEditing ? (
                        <SCText textField={speaker.fields?.LinkedInUrl} />
                    ) : (
                        <Link
                            href={speaker.fields.LinkedInUrl.value}
                            target="_blank"
                            letterSpacing="wide"
                            my="sp16"
                            variant="underlineLink"
                            width={"fit-content"}
                            size="sm"
                        >
                            {t("linkeinProfileLabel")}
                            <VisuallyHidden>for {speaker.fields.Name.value}</VisuallyHidden>
                        </Link>
                    )}
                </Box>
            )}
        </Box>
    );
};

export const Default = (props: SpeakersListProps) => {
    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                }}
                columnGap="sp40"
                rowGap={{ base: "sp64", lg: "sp128" }}
            >
                {props.fields.Speakers.map((speaker: SpeakerProps) => {
                    return <SpeakerBox key={speaker.id} {...speaker} />;
                })}
            </Grid>
        </GenericHeader>
    );
};

export const Columns4 = (props: SpeakersListProps) => {
    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                }}
                columnGap="sp40"
                rowGap={{ base: "sp64", lg: "sp128" }}
            >
                {props.fields.Speakers.map((speaker: SpeakerProps) => {
                    return <SpeakerBox key={speaker.id} {...speaker} />;
                })}
            </Grid>
        </GenericHeader>
    );
};
